import styled from 'styled-components';

export const StyledTable = styled.div`
  .row1, .row2{
    display: flex;
  }
  .col1{
    justify-content: space-around;
    width: 70%;
    white-space: pre-wrap;
  }
  .cols{
    padding: 10px;
    text-align: center;
    white-space: pre-wrap;
  }
  .title{
    color: #0562c7;
  }
  .date1, .date2{
    width: 253px;
    padding: 5px;
    white-space: pre-wrap;
  }
  .date1{
    background-color: lightblue;
  } 
  .date2{
    margin-top: 5px;
    background-color: #0562c7;
    color: white;
  }
  p {
    padding: 0;
    margin: 0;
  }
`;
