import React from 'react';
import { useTranslation } from 'react-i18next';
import { FooterSection, FooterCardsContainer, FooterCard, FooterTitle, FooterCardText, 
  FooterWrapperText, FooterCallCenter, FooterCallCenterFree, FooterAnticorBlock, FooterAnticorImg } from './FooterStyle';
import { Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Content } from '../../components/ThemeProvider/ThemeStyle';
import whiteLogo from '../../_assets/images/whiteLogo.png';
import Img1424 from '../../_assets/images/1424.jpg';
import LinkApp from './../../pages/Market/LinkApp';

import paths from '../../_helpers/paths';
import {footer_services, footer_services2} from './footerServices'
import FooterItem from './FooterItem'
import { history } from '../../_helpers/history';
import RuKindergartenInstruction from '../../_assets/files/ru_kindergarten_instruction.pdf';
import KKkindergartenInstruction from '../../_assets/files/kk_kindergarten_instruction.pdf';
import './FooterTranslate'

export const useStyles = makeStyles({
  typography: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    color: '#fff',
    fontSize: '14px',
    lineHeight: '140%',
    fontWeight: '400'
  }
});

function Footer({contentWidth}) {
  const { t, i18n } = useTranslation();
  const { typography } = useStyles();
  return (
    <FooterSection>
      {
        history.location.pathname === '/kindergarten-services' ?
        <>
          <Content contentWidth={contentWidth}>
            <FooterTitle onClick={() => history.push('/')}>
              <img src={whiteLogo} alt={t('home_title')}/>
              <div>{t('home_title')}</div>
            </FooterTitle>
          </Content>
          <Content contentWidth={contentWidth}>     
            <FooterCardsContainer>
              <FooterCard>
                <FooterCardText>
                  <Link
                    to={paths.homePage}
                    children={
                      <Typography color="secondary" children={t('home')} className={typography} />
                    }
                  />
                </FooterCardText>
                {Object.values(footer_services).map((metadata, index) => (
                    <FooterItem
                        key={index}
                        metadata={metadata}
                    />
                ))}
              </FooterCard>
              <FooterCard>
                {Object.values(footer_services2).map((metadata, index) => (
                    <FooterItem
                        key={index}
                        metadata={metadata}
                    />
                ))}
              </FooterCard>
              <FooterCard>
                <FooterCardText>
                  <Typography
                    color="secondary"
                    children={t('home_support')}
                    className={typography}
                    onClick={() => {
                      window.open('https://e-zhetisu.supportsystem.com/index.php', '_blank');
                    }}
                  />
                </FooterCardText>
                <FooterCardText>
                  <Link
                    to={paths.faqPage}
                    children={
                      <Typography color="secondary" children={t('home_frequently_asked_questions')} className={typography} />
                    }
                  />
                </FooterCardText>
                <FooterCardText>
                  <Typography
                    color="secondary"
                    children={t('home_user_guide')}
                    className={typography}
                    onClick={() => {
                      window.open(i18n.language === 'ru' ? RuKindergartenInstruction : KKkindergartenInstruction, '_blank');
                  }}
                  />
                </FooterCardText>
              </FooterCard>
            </FooterCardsContainer>
          </Content>
        </>
        :
     
        <Content contentWidth={contentWidth}>
          <FooterTitle onClick={() => history.push('/')}>
            <img src={whiteLogo} alt={t('home_title')}/>
            <div>{t('home_title')}</div>
          </FooterTitle>
          <div>
            <FooterWrapperText>
              <FooterCardText>
                <Typography
                  onClick={() => {
                    window.open('https://e-zhetisu.supportsystem.com/index.php', '_blank');
                  }}
                  color="secondary" 
                  children={t('home_support')} 
                  className={typography}
                />
              </FooterCardText>
              <FooterCardText>
                <Link
                  to={paths.faqPage}
                  children={
                    <Typography color="secondary" children={t('home_frequently_asked_questions')} className={typography} />
                  }
                />
              </FooterCardText>
            </FooterWrapperText>
            <div style={{margin: '10px 0', display: 'flex', justifyContent: 'right'}}>
              <LinkApp />
            </div>
            <FooterAnticorBlock>
              <FooterAnticorImg>
                <img alt='' src={Img1424} style={{width: '100%'}} />
              </FooterAnticorImg>
                <div style={{marginLeft: '10px'}}>
                  <FooterCallCenter>
                    <span>{t('footer_encounter_corruption')}</span>
                    <span> {t('footer_contact_call')}</span>
                  </FooterCallCenter>
                  <FooterCallCenterFree>
                    {t('footer_call_free')}
                  </FooterCallCenterFree>
                </div>
            </FooterAnticorBlock>
          </div>
          
        </Content>
      }
    </FooterSection>
  );
}

export default Footer;
