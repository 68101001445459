import React from 'react';
import appStore from '../../_assets/images/appStore.png'

function MarketApple() {
  return (
    <a href='https://apps.apple.com/kz/app/e-zhetysu-qr/id6473778026'>
      <img alt='' src={appStore} />
    </a>
  );
}

export default MarketApple;
