import React from 'react';
import { StyledTable, StyledBlockImg } from './TableColumnStyle';
import { Link } from 'react-router-dom';
const styles = {
  position: 'relative'
};

export const requestsColumns = ({ t, lang }) => {
  return [
    {
      Cell: row => <Column row={row} t={t} lang={lang} />,
      style: styles
    }
  ];
};

const Column = ({ row, t, lang }) => {
  return (
    <StyledTable>
      <div>
        <Link to={`/kindergarten-reestr/${row.original.id}`}>
          <div className="cont1">
            <div style={{height: '100%'}}>
            {row.original.photo_do ? (
              <img src={row?.original?.photoDDO} className="do_image" alt={''} />
            ) : (
              <StyledBlockImg>
                {t('kindergarten_reestr_noPhoto')}
              </StyledBlockImg>
            )}
            </div>
          </div>
          <div className="cont2">
            <div className="row1">
              <div className="col1">
                <div>
                  <b>{lang === 'ru' ? row.original.ru_name : row.original.kk_name}</b>
                </div>
                <div>
                  {t('kindergarten_reestr_table_lang')}{' '}
                  {lang === 'ru'
                    ?
                    row.original.langs.map(item => {
                      return item.ru_name+" "
                    })
                    :
                    row.original.langs.map(item => {
                      return item.kk_name+" "
                    })
                  }
                  <br />
                  {t('kindergarten_reestr_table_address')}{' '}
                  {lang === 'ru'
                    ? row.original.legal_address
                    : row.original.legal_address_kz}
                </div>
              </div>
              <div className="col2">
                {lang === 'ru' ? row.original.ownership_ru : row.original.ownership_kk}
              </div>
            </div>
          </div>
          <div className="row2">
            <div className="counts">
              {t('kindergarten_reestr_table_seats')} {row.original.number_seats}
            </div>
            <div className="counts">
              {t('kindergarten_reestr_table_total_seats')}{' '}
              {row.original.design_capacity}
            </div>
          </div>
        </Link>
      </div>
    </StyledTable>
  );
};
