import { addTranslation } from '../../_helpers/Translate';

addTranslation({
  ru: {
    kindergarten_applications_subtitle: 'Заявления',
    kindergarten_applications_number: '№',
    kindergarten_applications_queue_number: '№ очереди',
    kindergarten_applications_application_number: '№ Заявления',
    kindergarten_applications_iin_child: 'ИИН ребенка',
    kindergarten_applications_fio_child: 'ФИО ребенка',
    kindergarten_applications_birthdate: 'Дата рождения',
    kindergarten_applications_queue_type: 'Тип очереди',
    kindergarten_applications_status: 'Статус заявки',
    kindergarten_applications_benefit: 'Приоритетность в очереди',
    kindergarten_applications_actions_revoke: 'Отозвать заявление из очереди',
    kindergarten_applications_actions_get_appointment: 'Получить направление',
    kindergarten_applications_error_load: 'Ошибка при загрузке данных',
    kindergarten_applications_modal_title_revoke: 'Отзыв заявления',
    kindergarten_applications_modal_body_revoke:
      'Вы действительно хотите отозвать заявление из очереди? При отзыве, полученное заявление аннулируется и будет отправлено в архив',
    kindergarten_applications_modal_yes_revoke: 'Да',
    kindergarten_applications_modal_no_revoke: 'Нет',
    kindergarten_applications_modal_title_interested:
      'Подтверждение заинтересованности в получении места в ДДО',
    kindergarten_applications_modal_body_interested: 'Подтвердите свое действие',
    kindergarten_applications_modal_full_name_interested: 'ФИО ребенка:',
    kindergarten_applications_modal_number_interested: 'Номер очереди:',
    kindergarten_applications_modal_ok_button_interested: 'Подтвердить',
    kindergarten_applications_modal_cancel_button_interested: 'Отмена',
    kindergarten_applications_actions_download: 'Скачать заявление',
    kindergarten_applications_error_revoke_applicant: 'Не удалось отозвать заявление',
    kindergarten_applications_error_confirm_interest: 'Не удалось выполнить заапрос',
    kindergarten_applications_check_bulletin_error:
      'Не удалось проверить возможность получение направления',
    kindergarten_applications_check_bulletin_empty: 'В данный момент свободных мест нет.',
    kindergarten_applications_button_interested_text: 'Заинтересован',
    kindergarten_applications_region: 'Регион:',
    kindergarten_applications_application_date: 'Дата подачи заявления:',
    kindergarten_applications_date_issue_referral: 'Дата выдачи направления:',
    kindergarten_applications_referral_validity_period: 'Срок действия направления:',
    kindergarten_applications_date_transition_stop_list: 'Дата перехода в стоп-лист:',
    kindergarten_applications_date_exit_stop_list: 'Дата завершения:',
    kindergarten_applications_archive_date: 'Дата перехода в архив:',
    kindergarten_applications_from: 'от',
    kindergarten_applications_selectDDO: 'Выбрать ДДО',
    kindergarten_applications_selectDDO_no: 'Нет',
    kindergarten_applications_selectDDO_yes: 'Подписать',
    kindergarten_applications_selectDDO_title: 'Просим Вас выбрать предпочитаемые садики, в которые хотели бы зачислить ребенка (до 4-х дошкольных организации)',
    kindergarten_applications_DDO: 'ДДО №',
    kindergarten_applications_selectDDO_btn: 'Анкетирование',
    kindergarten_applications_selectDDO_not_found: 'По запросу ничего не найдено',
    kindergarten_applications_saveSuccess: 'Дошкольные организации выбраны',
    kindergarten_applications_saveFailed: 'Не удалось подписать',
    kindergarten_applications_required_fill: 'Обязательно для заполнения',
    kindergarten_applications_signCanceled: 'Подпись отменена',
    kindergarten_applications_download_application: 'Скачать заявление'
  },
  kk: {
    kindergarten_applications_subtitle: 'Өтініштер',
    kindergarten_applications_number: '№',
    kindergarten_applications_queue_number: 'Кезек №',
    kindergarten_applications_application_number: 'Өтініш №',
    kindergarten_applications_iin_child: 'Баланың ЖСН-і',
    kindergarten_applications_fio_child: 'Баланың аты-жөн',
    kindergarten_applications_birthdate: 'Туған күні',
    kindergarten_applications_queue_type: 'Кезек түрі',
    kindergarten_applications_status: 'Өтініш мәртебесі',
    kindergarten_applications_benefit: 'Кезек басымдылығы',
    kindergarten_applications_actions_revoke: 'Өтінішті кезектен алу',
    kindergarten_applications_actions_get_appointment: 'Жолдама алу',
    kindergarten_applications_error_load: 'Деректерді жүктеуде қателік орын алды',
    kindergarten_applications_modal_title_revoke: 'Өтінішті кезектен алу',
    kindergarten_applications_modal_body_revoke:
      'Сіз өтінішіңізді кезектен қайтарып алғыңыз келетініне сенімдісіз бе? Қайтарылғаннан кейін қабылданған өтініштің күші жойылады және мұрағатқа жіберіледі',
    kindergarten_applications_modal_yes_revoke: 'Иә',
    kindergarten_applications_modal_no_revoke: 'Жоқ',
    kindergarten_applications_modal_title_interested:
      'МДҰ-ға орын алуға мүдделігін растау',
    kindergarten_applications_modal_body_interested: 'Әрекетіңізді растаңыз',
    kindergarten_applications_modal_full_name_interested: 'Баланың аты-жөні:',
    kindergarten_applications_modal_number_interested: ' Кезектегі нөмірі:',
    kindergarten_applications_modal_ok_button_interested: 'Растау',
    kindergarten_applications_modal_cancel_button_interested: 'Болдырмау',
    kindergarten_applications_actions_download: 'Өтінішті жүктеу',
    kindergarten_applications_error_revoke_applicant:
      'Өтінішті кезектен алу мүмкін болмады,',
    kindergarten_applications_error_confirm_interest: 'Сұранысты орындау мүмкін болмады',
    kindergarten_applications_check_bulletin_error:
      'Жолдаманы алу мүмкіндігін тексеру мүмкін болмады',
    kindergarten_applications_check_bulletin_empty: ' Қазіргі мезетте бос орындар жоқ.',
    kindergarten_applications_button_interested_text: 'Орын алуға мүдделігін растау',
    kindergarten_applications_region: 'Аймақ:',
    kindergarten_applications_application_date: 'Өтініш беру уақыты:',
    kindergarten_applications_date_issue_referral: 'Жолдаманың берілу мерзімі:',
    kindergarten_applications_referral_validity_period: 'Жолдаманың жарамдылық мерзімі:',
    kindergarten_applications_date_transition_stop_list: 'Тоқтату парағына өту уақыты:',
    kindergarten_applications_date_exit_stop_list: 'Аяқталу уақыты:',
    kindergarten_applications_archive_date: 'Мұрағатқа өту уақыты:',
    kindergarten_applications_from: 'бастап',
    kindergarten_applications_selectDDO: 'МДҰ-ды таңдау',
    kindergarten_applications_selectDDO_no: 'Жоқ',
    kindergarten_applications_selectDDO_yes: 'Қол қою',
    kindergarten_applications_selectDDO_title: 'Балаңызды тіркегіңіз келетін балабақшаны таңдауыңызды сұраймыз (4 мектепке дейінгі ұйымға дейін)',
    kindergarten_applications_DDO: 'МДҰ №',
    kindergarten_applications_selectDDO_btn: 'Сауалнама',
    kindergarten_applications_selectDDO_not_found: 'Сұранысыңыз бойынша ақпарат табылмады',
    kindergarten_applications_saveSuccess: 'Мектепке дейінгі ұйымдар таңдалды',
    kindergarten_applications_saveFailed: 'Қол қою сәтсіз аяқталды',
    kindergarten_applications_required_fill: 'Толтыру қажет',
    kindergarten_applications_signCanceled: 'Қол қою жойылды',
    kindergarten_applications_download_application: 'Жолданманы жүктеп алыңыз'
  }
});
