import { createReducer } from 'redux-starter-kit';
import { KindergartenApplicationsApi } from './KindergartenApplicationsApi';
import Notice from '../../modules/utils/Notice';
import { handleError } from '../../modules/utils/handleError';
import i18next from 'i18next';
import * as Dict from '../../modules/utils/Dict';
import { NCALayerSign } from '../../components/NCALayer/NCALayerSign';
import { countNotifications } from '../KindergartenNotifications/KindergartenNotificationsDucks';

/**
 * Constants
 */

export const kindergartenApplicationsModule = 'kindergartenApplications';
const SET_DATA = `${kindergartenApplicationsModule}/SET_DATA`;
const LOADING = `${kindergartenApplicationsModule}/LOADING`;
const CHECK_LOADING = `${kindergartenApplicationsModule}/CHECK_LOADING`;
const CLEAR_STATE = `${kindergartenApplicationsModule}/CLEAR_STATE`;
const SET_LIST_DDO = `${kindergartenApplicationsModule}/SET_LIST_DDO`;

/**
 * Reducer
 */

const initialState = {
  loading: true,
  check_loading: false,
  data: [],
  pageNumber: 1,
  totalElements: 0,
  listDDO: []
};

export default createReducer(initialState, {
  [SET_DATA]: (state, action) => {
    state.data = action.data;
    state.pageNumber = action.pageNumber;
    state.totalElements =
      action.pageNumber === 1 ? action.totalElements : state.totalElements;
  },
  [LOADING]: (state, action) => {
    state.loading = action.loading;
  },
  [CHECK_LOADING]: (state, action) => {
    state.check_loading = action.check_loading;
  },
  [SET_LIST_DDO]: (state, action) => {
    state.listDDO = action.payload;
  },
  [CLEAR_STATE]: () => initialState
});

export const clearState = () => ({ type: CLEAR_STATE });

export const loadApplications = filter => async dispatch => {
  try {
    dispatch({ type: LOADING, loading: true });

    const { data } = await KindergartenApplicationsApi.getApplications(
      JSON.stringify(filter)
    );
    for (let row of data.result.content) {
      row.providerLocation = await Dict.item('60', row.queuedRegion);
    }
    if (data.status === 'SUCCESS') {
      const content = [];
      for (let item of data.result.content) {
        let queuedType = item.queuedType
          ? await Dict.item('gu_queued_type', item.queuedType || '')
          : '';
        let appStatusWeb = item.appStatusWeb
          ? await Dict.item('gu_app_status_web', item.appStatusWeb || '')
          : '';
        let priorityQueued = item.priorityQueued
          ? await Dict.item('gu_priority_queued', item.priorityQueued || '')
          : '';
        content.push({
          ...item,
          queuedTypeStatus: queuedType,
          appStatusWebStatus: appStatusWeb,
          priorityQueuedStatus: priorityQueued,
          canGetNapravlenie: false
        });
      }
      dispatch({
        type: SET_DATA,
        data: content,
        pageNumber: data.result.pageNumber,
        totalElements: data.result.totalElements
      });
    } else {
      Notice.error(i18next.t('kindergarten_applications_error_load'));
    }
  } catch (error) {
    handleError(error, i18next.t('kindergarten_applications_error_load'));
  } finally {
    dispatch({ type: LOADING, loading: false });
  }
};

export const checkCanGetNapravlenie = (data, push) => async dispatch => {
  try {
    dispatch({ type: CHECK_LOADING, check_loading: true });
    let filter = {
      locationCode: data.queuedRegion,
      statusWeb: data.appStatus === 'IN_QUEUED' ? 'available_all' : null,
      childBirthDay: data.childBirthday,
      queuedType: data.queuedType,
      rehabType: data.rehabType,
      correctionType: data.correctionType
    };
    const response = await KindergartenApplicationsApi.getBulletins(filter);
    if (response.data.content) {
      let res = getKeyValues(response.data.content);
      let check = false;
      for (let i = 0; i < res.length; i++) {
        if (res[i].available_sits > 0) {
          check = true;
          break;
        }
      }
      if (check) {
        push(
          `/bulletin-open-places/${data.id}?filter=${JSON.stringify({
            pageNumber: 1,
            pageSize: 10,
            locationCode: data.queuedRegion,
            statusWeb: data.appStatus === 'IN_QUEUED' ? 'available_all' : null,
            childBirthDay: data.childBirthday,
            queuedType: data.queuedType,
            rehabType: data.rehabType,
            correctionType: data.correctionType
          })}`
        );
      } else {
        Notice.error(i18next.t('kindergarten_applications_check_bulletin_empty'));
        return false;
      }
    } else {
      Notice.error(i18next.t('kindergarten_applications_check_bulletin_error'));
    }
    dispatch(countNotifications());
  } catch (error) {
    console.log(error);
    handleError(error, i18next.t('kindergarten_applications_check_bulletin_error'));
  } finally {
    dispatch({ type: CHECK_LOADING, check_loading: false });
  }
};

const getKeyValues = data => {
  let res = [];
  data.forEach(item => {
    let obj = {};
    item.passportValues.forEach(a => {
      obj[a.code] = a.value;
    });
    res.push(obj);
  });
  return res;
};

export const revokeByApplicant = (id, modal, filter) => async dispatch => {
  try {
    dispatch({ type: LOADING, loading: true });
    const { data } = await KindergartenApplicationsApi.getSignStringForRevoke({ id: id });
    const signature = await NCALayerSign(data.result.signature);
    if (signature) {
      await KindergartenApplicationsApi.revokeByApplicantApi(id, {
        signature
      });
      dispatch(loadApplications(filter));
      modal.close();
    }
    dispatch(countNotifications());
  } catch (error) {
    handleError(error, i18next.t('kindergarten_applications_error_revoke_applicant'));
  } finally {
    dispatch({ type: LOADING, loading: false });
  }
};
export const confirmInterest = (id, modal, filter) => async dispatch => {
  try {
    dispatch({ type: LOADING, loading: true });
    await KindergartenApplicationsApi.confirmInterestApi(id);
    dispatch(loadApplications(filter));
    modal.close();
    dispatch(countNotifications());
  } catch (error) {
    handleError(error, i18next.t('kindergarten_applications_error_confirm_interest'));
  } finally {
    dispatch({ type: LOADING, loading: false });
  }
};

export const getListDDO = (id) => async (dispatch) => {
  try {
    dispatch({ type: LOADING, loading: true });
    const { data } = await KindergartenApplicationsApi.getListDDO(id);
    if (data.status === 'SUCCESS') {
      dispatch({ 
        type: SET_LIST_DDO, 
        payload: data.result
      })
    }
  } catch (error) {
    handleError(error, i18next.t('kindergarten_applications_error_load'));
  } finally {
    dispatch({ type: LOADING, loading: false });
  }
}

export const signatureListDDO = ({ values, id, handleClose }) => async (dispatch, getState) => {
  try {
    dispatch({ type: LOADING, loading: true });
    let kindergartenIds = []
    const { data } = await KindergartenApplicationsApi.signatureListDDO({ id: id });
    for (let item in values) {
      if (values[item]) {
        kindergartenIds.push(values[item]?.id)
      }
    }
    const signature = await NCALayerSign(data.result.signature);
    if (!signature) {
      return Notice.warning(i18next.t('kindergarten_applications_signCanceled'));
    } else {
      const lists = await KindergartenApplicationsApi.choiceListDDO({
        kindergartenQueueId: id,
        kindergartenIds,
        signature
      })
      const state = getState()[kindergartenApplicationsModule];
      const stateCopy = JSON.parse(JSON.stringify(state.data));
      const index = stateCopy.findIndex((el) => el.id === lists.data.result.id);
      if (index !== -1) {
        stateCopy[index] = {
          ...stateCopy[index],
          interestedKindergartenShortInfos: lists?.data?.result.interestedKindergartenShortInfos
        }
      }
      dispatch({ type: SET_DATA, data: stateCopy });
      handleClose();
      Notice.success(i18next.t('kindergarten_applications_saveSuccess'));
    }
  } catch (error) {
    handleError(error, i18next.t('kindergarten_applications_saveFailed'));
  } finally {
    dispatch({ type: LOADING, loading: false });
  }
}

export const applicationDownload = ({ requestFormId }) => async (dispatch) => {
  try {
    dispatch({ type: LOADING, loading: true });
    const response = await KindergartenApplicationsApi.applicationDownload(requestFormId);
    download(response.data, `${i18next.t('kindergarten_applications_subtitle')}-${requestFormId}.pdf`);
  } catch (error) {
    handleError(error, i18next.t('kindergarten_applications_error_load'));
  } finally {
    dispatch({ type: LOADING, loading: false });
  }
}

const download = (data, fileName) => {
  let element = document.createElement('a');
  document.body.appendChild(element);
  element.style.display = 'none';
  let blob = new Blob([data]);
  let url = window.URL.createObjectURL(blob);
  element.href = url;
  element.download = fileName;
  element.click();
  window.URL.revokeObjectURL(url);
};
